<template>
    <div class="about-us">
        <img
        class="pic1"
        referrerpolicy="no-referrer"
        src="@/assets/about-us/banner.jpg"
        />
        <div class="box1 flex-row jus-center ali-center">
            <div class="flex-row jus-between">
                <div class="left flex-col">
                    <div class="title flex-row ali-center jus-center">
                        <div class="commonTitle">公司简介</div>
                    </div>
                    <!-- <div class="sTitle">COMPANY PROFILE</div> -->
                    <div class="desc">
                        湖北九域聚合科技有限公司成立于2018年，是一家基于物联网、大数据、人工智能技术应用专注于健康管理的综合解决方案提供商。
                    </div>
                    <div class="desc">
                        公司依托于健康知识科普教育、健康状况监测评估、健康风险干预管理三大核心产品体系聚合赋能提供9大应用场景，包括线下健康科普基地、线上健康服务平台、健康风险干预系统、健康社区、健康家庭、健康校园、健康企业、健康乡村、健康公园解决方案为一体的高新技术企业与双软认证企业。
                    </div>
                    <div class="desc">
                        公司具有先进的技术研发平台，扎实的软硬件研发团队，目前获得各类知识产权60余项，同时与多家知名健康管理研究机构建立联系合作。 公司自主知识产权的智慧健康管理服务平台，为用户提供健康状况监测、健康知识教育、健康风险干预于一体的健康服务，并构建线上线下结合的服务场景，加强全人群健康知识普及，推广文明健康生活方式，形成每个人是自己健康第一责任人理念；助力公卫部门健康素养促进项目工作深入推进，提高人民健康水平。目前已经与多个地市政府机关、企事业单位合作，提供一站式综合健康管理解决方案及应用服务。
                    </div>
                </div>
                <div class="right"><img class="img1" src="@/assets/about-us/img1.jpg" alt="" srcset=""></div>
            </div>
        </div>
        <div class="box2 flex-row jus-center ali-center">
            <div class="flex-row ali-center">
                <div class="left"><img class="img2" src="@/assets/about-us/img2.jpg"></div>
                <div class="right flex-col">
                    <div>
                        <div class="title flex-row ali-center jus-center">
                            <div class="commonTitle">企业文化</div>
                        </div>
                        <!-- <div class="sTitle">CORPORATE CULTURE</div> -->
                    </div>
                    <div class="rightContent flex-col jus-between">
                        <div v-for="item in rightContent" :key="item">
                            <div class="name">{{ item.name }}</div>
                            <div>{{ item.desc }}</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="box3 flex-col ali-center">
            <div class="title flex-row ali-center jus-center">
                <div class="commonTitle">公司资质</div>
            </div>
            <!-- <div class="sTitle">COMPANY QUALIFICATION</div> -->
            <img class="img3" src="@/assets/about-us/img3.png" alt="" srcset="">
            <img class="img4" src="@/assets/about-us/img4.png" alt="" srcset="">
            <img class="img5" src="@/assets/about-us/img5.png" alt="" srcset="">
        </div>
    </div>
</template>

<script>
    export default {
        name: 'AboutUs',
        data() {
            return {
                rightContent: [
                    {name: '企业定位', desc: '健康知识传播及慢病管理平台研究与应用服务专家'},
                    {name: '企业核心价值观', desc: '珍爱健康、信仰科学、激情、快乐'},
                    {name: '企业愿景', desc: '倡导健康科学生活方式，降低疾病负担，提高生活品质'},
                ],
              userform:null,
            }
        },
		mounted() {
			 window.scrollTo(0,0);
      this.userfrom = window.sessionStorage.getItem("from")
      this.$mybus.emit('userBehavior', {from:this.userfrom,moduleLevel1:6})
		}
    }
</script>

<style lang="stylus" scoped>
.about-us {
    padding-top 80px
}

.pic1 {
  height: 600px;
  width: 100%;
  display: block;
}
.title
    font-size: 26px;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: rgba(0, 0, 0, 0.85);
    line-height: 37px;
    margin-bottom 30px
.sTitle
    font-size: 20px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: rgba(0, 0, 0, 0.5);
    line-height: 28px;
    margin-bottom 50px
.box1
    width 100%
    height 715px
    background #fff
    > div
        width 1173px
        // height 555px
    .img1
        // margin-top 115px
        width 376px
        height 376px
        display block
    .left
        width 697px
        .desc
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            font-size: 14px;
            color: #333333;
            line-height: 35px;
            letter-spacing: 1px;
            text-align: left;
            margin-bottom: 10px;
            text-indent: 2em;
    .right
        padding-top 115px
    // .right
    //     width 376px
    //     height 376px
    //     margin-top 115px
.box2
    width 100%
    height 540px
    background url('../assets/about-us/bg1.png') no-repeat
    background-size 100% 100%
    margin-bottom 80px
    > div
        width 1200px
        height 424px
    .right
        flex 1
        height 100%
    .img2
        width 376px
        height 376px
        margin-right 244px
        display block
    .rightContent
        flex 1
        text-align left
        .name
            font-size: 20px;
            font-family: FontName;
            color: #025D91;
            line-height: 24px;
            letter-spacing: 1px;
            padding-bottom 15px
            margin-bottom 15px
            position relative
            &:before
                position absolute
                bottom 0
                left 0
                content ''
                width: 100px;
                height: 1px;
                background: linear-gradient(270deg, rgba(255, 255, 255, 0) 0%, #025D91 100%);
            + div
                font-size: 18px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #333333;
                line-height: 35px;
                letter-spacing: 1px;
.box3
    width 100%
    height 1105px
    background url('../assets/about-us/bg2.png') no-repeat
    background-size 100% 100%
    .img3
        display block
        width 1200px
        height 272px
        margin-bottom 99px
        margin-top 20px
    .img4
        display block
        width 1200px
        height 203px
        margin-bottom 99px
    .img5
        display block
        width 668px
        height 207px
</style>
